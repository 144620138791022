.img-cover-h {
  max-height: 400px;
}

@media (min-wdith: 1024px) {
  .lg\:img-cover-h {
    height: 400px;
  }
}

@media (min-width: 1280px) {
  .xl\:img-cover-h {
    height: 600px;
  }
}

img {
  aspect-ratio: attr(width) / attr(height);
}

@keyframes ping-big {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  75%,
  100% {
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes spin-backwords {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes float {
  0%,
  100% {
    transform: translate(0, 0);
    animationtimingfunction: cubic-bezier(0.8, 0, 1, 1);
  }
  25% {
    transform: translate(4%, 6%);
    animationtimingfunction: cubic-bezier(0, 0, 0.2, 1);
  }
  50% {
    transform: translate(-4%, -3%);

    animationtimingfunction: cubic-bezier(0.8, 0, 1, 1);
  }
  75% {
    transform: translate(3%, 4%);
    animationtimingfunction: cubic-bezier(0, 0, 0.2, 1);
  }
}

.scrollbar-none {
  scrollbar-width: none;
}

.scrollbar-none::-webkit-scrollbar {
  display: none !important;
}

.scrollbar-w-2::-webkit-scrollbar {
  @apply w-2;
  @apply h-2;
}

.scrollbar-track-gray-lighter::-webkit-scrollbar-track {
  @apply bg-gray-300;
}

.scrollbar-thumb-gray::-webkit-scrollbar-thumb {
  @apply bg-gray-400;
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  @apply rounded;
}

::selection {
  @apply bg-green-400;
  @apply text-gray-900;
}

::-moz-selection {
  @apply bg-green-400;
  @apply text-gray-900;
}

%arrow {
  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;
}

.tippy-arrow {
  @extend %arrow;
}

.tippy-arrow::before {
  @extend %arrow;

  content: '';
  transform: rotate(45deg);

  @apply bg-white;
  @apply shadow-lg;
}

.tippy-content[data-popper-placement^='top'] > .tippy-arrow {
  bottom: -4px;
}

.tippy-content[data-popper-placement^='bottom'] > .tippy-arrow {
  top: -4px;
}

.tippy-content[data-popper-placement^='left'] > .tippy-arrow {
  right: -4px;
}

.tippy-content[data-popper-placement^='right'] > .tippy-arrow {
  left: -4px;
}

.h {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

@media (min-wdith: 1024px) {
  .lg\:hero-min-h {
    min-height: 400px;
  }
}

@media (min-width: 1280px) {
  .xl\:hero-min-h {
    min-height: 600px;
  }
}

.aibes-logo {
  @apply relative;
  @apply flex;
  @apply border-none;
  @apply rounded;
  @apply overflow-hidden;
  @apply z-10;

  width: 24px;
  height: 24px;
}

.aibes-logo .primary,
.aibes-logo .secondary,
.aibes-logo .tertiary {
  @apply block;
  @apply absolute;
  @apply top-0;
  @apply left-0;
  @apply w-full;
  @apply h-full;
}

.aibes-logo .primary {
  @apply bg-gray-900;
  @apply z-20;

  max-width: 0.53333rem;
  max-height: 0.53333rem;
}

.aibes-logo .secondary {
  @apply bg-green-500;
  @apply z-10;

  max-width: 1rem;
  max-height: 1rem;
}

.aibes-logo .tertiary {
  @apply bg-green-400;
  @apply z-0;
}

.aibes-logo.lg {
  @apply h-20;
  @apply w-20;
  @apply rounded-lg;
}

.aibes-logo.lg .primary,
.aibes-logo.lg .secondary,
.aibes-logo.lg .tertiary {
  @apply max-h-full;
  @apply max-w-full;
}

.aibes-logo.lg .primary {
  @apply h-8;
  @apply w-8;
}

.aibes-logo.lg .secondary {
  @apply h-14;
  @apply w-14;
}

.aibes-logo.lg .tertiary {
  @apply h-20;
  @apply w-20;
}

.aibes-logo.xl {
  @apply h-32;
  @apply w-32;
  @apply rounded-lg;
}

.aibes-logo.xl .primary,
.aibes-logo.xl .secondary,
.aibes-logo.xl .tertiary {
  @apply max-h-full;
  @apply max-w-full;
}

.aibes-logo.xl .primary {
  @apply h-10;
  @apply w-10;
}

.aibes-logo.xl .secondary {
  @apply h-20;
  @apply w-20;
}

.aibes-logo.xl .tertiary {
  @apply h-32;
  @apply w-32;
}

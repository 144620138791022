/*! purgecss start ignore */
@tailwind base;
@tailwind components;

@import './fonts.css';

@import './common/brand.css';
@import './common/extra.css';

@import './domain/hero.css';

/*! purgecss end ignore */

@tailwind utilities;
